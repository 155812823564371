import React, { useEffect, useRef, useState } from 'react';

const CustomCarousel = () => {
  const carouselContainerRef = useRef(null);

  const scrollByAmount = 500;
  const [activeSlide, setActiveSlide] = useState(0);
  const totalSlides = 5; 

  const prevSlide = () => {
    if (carouselContainerRef.current) {
      const container = carouselContainerRef.current;
  
      if (container.scrollLeft - scrollByAmount< 0) {
        // If reaching the beginning, jump to the end
        container.scrollTo({
          left: container.scrollWidth - container.clientWidth,
          behavior: 'smooth',
        });
        setActiveSlide(totalSlides - 1);
      } else {
        // Scroll to the previous position
        container.scrollBy({
          left: -scrollByAmount,
          behavior: 'smooth',
        });
        setActiveSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
      }
    }
  };
  
  const handleDotClick = (index) => {
    if (carouselContainerRef.current) {
      const scrollAmount = index * scrollByAmount;
      carouselContainerRef.current.scrollTo({
        left: scrollAmount,
        behavior: 'smooth',
      });
      setActiveSlide(index);
    }
  };
  const nextSlide = () => {
    if (carouselContainerRef.current) {
      const container = carouselContainerRef.current;
      const maxScrollLeft = container.scrollWidth - container.clientWidth;

      if (container.scrollLeft + container.clientWidth + 500 > maxScrollLeft) {
        // If reaching the end, jump back to the start
        container.scrollTo({
          left: 0,
          behavior: 'smooth',
        });
        setActiveSlide(0);
      } else {
        // Scroll to the next position
        container.scrollBy({
          left: scrollByAmount,
          behavior: 'smooth',
        });
        setActiveSlide((prevSlide) => (prevSlide + 1) % totalSlides);
      }
    }
  };


  useEffect(() => {
    const scrollInterval = setInterval(() => {
      nextSlide(); // Adjust the direction as needed
    }, 8000);
    
    return () => {
      clearInterval(scrollInterval);
    };
  }, []);
  
  useEffect(() => {
    // Calculate the active dot styles based on the current active slide
    const dots = document.querySelectorAll('.dot');
    dots.forEach((dot, index) => {
      dot.style.transition = 'width 0.3s ease-out'; // Adjust the duration and easing as needed
      if (index === activeSlide) {
        dot.style.width = '30px'; // Adjust the elongation value
      } else {
        dot.style.width = '8px'; // Adjust the normal width
      }
    });
  
    // Clear the transition after it completes
    const transitionTimeout = setTimeout(() => {
      dots.forEach((dot) => {
        dot.style.transition = '';
      });
    }, 500); // Adjust the timeout to match the transition duration
  
    return () => {
      clearTimeout(transitionTimeout);
    };
  }, [activeSlide]);

  return (
    <div className="custom-carousel border border-black  rounded-sm">
      <div className="carousel-container rounded-sm h-72 object-cover no-scrollbar  overflow-scroll "
        ref={carouselContainerRef}>
    <img src="images/1.jpg " className='bg-cover select-none' alt="Slide 1" />
    <img src="images/2.jpg" alt="Slide 2" />
    <img src="images/3.jpg" className='' alt="Slide 3" />
    <img src="images/5.jpg" className='' alt="Slide 4" />
    <img src="images/6.png" className='' alt="Slide 5" />
    <img src="images/7.png" className='' alt="Slide 6" />
    <img src="images/9.jpg" className='   ' alt="Slide 7" />
      </div>
      <div className="dots-container absolute bottom-3 left-1/2 transform -translate-x-1/2 flex space-x-[4px]">
        {[...Array(totalSlides).keys()].map((index) => (
          <div
            key={index}
            className={`${index===activeSlide?"opacity-90":"opacity-60"} dot h-2 transition-all  bg-black  rounded-full cursor-pointer`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
      <button
        className="prev-button absolute top-1/2 left-0 transform translate-x-1 -translate-y-1/2 flex items-center justify-center text-white opacity-50 bg-black rounded-full h-6 w-6 cursor-pointer"
        onClick={prevSlide}
      >
        <img src='/assets/left-arrow.svg' alt='left' width={"8px"}/>
      </button>
      <button
        className="next-button absolute top-1/2 right-0 transform -translate-x-1 -translate-y-1/2 flex items-center justify-center text-white bg-black opacity-50 rounded-full h-6 w-6 cursor-pointer"
        onClick={nextSlide}
      >
        <img src='/assets/right-arrow.svg' alt=' right' width={"8px"}/>
        
      </button>
    </div>
  );
};

export default CustomCarousel;
