// frontend/src/components/App.js
import React, { useLayoutEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Contents from "./pages/Contents";
import Outcome from "./pages/learning-outcome";
import Teams from "./pages/teams";
import Speakers from "./pages/Speakers";
import Error from "./pages/error";
import Workshop from "./pages/workshop";
function App() {
  const Wrapper = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  } 
  return (
    <BrowserRouter>
    <Wrapper>
    <Routes>
      <Route path="*" element={<Error/>} />
      <Route path="/" element={<Home/>} />
      <Route path="/contents" element={<Contents/>} />
      <Route path="/learning-outcome" element={<Outcome/>} />
     <Route path="/team" element={<Teams/>} />
     {/* <Route path="/guest-speakers" element={<Speakers/>} /> */}
     <Route path="/workshop" element={<Workshop/>} />
    </Routes>
    </Wrapper>
  </BrowserRouter>
  );
}

export default App;
