import React from 'react'
import { Link } from 'react-router-dom'

function Error() {
  return (
    <div className=' md:mt-[12%] mt-[40%] flex justify-center items-center gap-10 flex-col md:text-3xl md:font-extrabold text-[#CC523F]'>
      <img src='/assets/page-not-found.png' alt='404-error' className='  md:w-1/4 w-3/4'/>
      <div className=' text-black text-lg font-thin'>
        Back to <Link  className='  text-[#CC523F] underline font-extralight' to='/'>Home</Link>
      </div>
    </div>
  )
}

export default Error
