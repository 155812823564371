import React, {  useState } from "react";

function FAQ() {
  const faqData = [
    {
      question: "Who is eligible to participate in this program?",
      answer:
        " The program is designed for materials engineers, industry professionals,undergraduate students, postgraduate students, and researchers with a foundational understanding of materials science. Participants from both academia and industry are encouraged to apply. Participants has to be from UK and India only.",
    },
    {
        "question": "What makes this program unique?",
        "answer": "This program stands out due to its comprehensive curriculum that spans theoretical foundations, practical testing, and advanced material analysis, combined with the unique opportunity for cross-country and academia-industry collaboration, offering participants a global perspective and real-world application of materials science."
      },
      {
        "question": "How long is the program and what is the structure?",
        "answer": "The program runs from March to November 2024 and is structured into three phases: Theoretical Foundations, Practical Testing and Analysis, and Material Characterization and Analysis, each followed by workshops and real data analysis projects."
      },
      {
        "question": "Are there any online components of the program?",
        "answer": "Yes, the program includes online lectures, digital resources, and remote collaboration projects to accommodate participants from various countries and facilitate cross-country collaboration."
      },
      {
        "question": "What is the application process for the program?",
        "answer": "Applicants must submit an online application form, including their educational background, professional experience, and a statement of purpose explaining their interest in the program. Selected candidates may be invited for an interview."
      },
      {
        "question": "Is there a program fee, and are scholarships available?",
        "answer": "No, there is no program fee."
      },
      {
        "question": "How does the program facilitate academia-industry collaboration?",
        "answer": "The program includes guest lectures from industry professionals, real-world case studies, and collaborative projects with industry partners. Participants will have opportunities to work on industry-relevant challenges and network with professionals in their field. Curriculum is jointly developed by consortium of Academia and Industry."
      },
      {
        "question": "Can participants from different countries collaborate on projects?",
        "answer": "Yes, the program is designed to encourage cross-country collaboration, with participants grouped into international teams for certain projects and assignments (Limited to participants from India and UK). This fosters a global exchange of ideas and practices."
      },
      {
        "question": "What are the expected outcomes for participants?",
        "answer": "Participants will gain a deep understanding of materials science principles, real data experience with testing and characterization techniques, and the ability to apply this knowledge in real-world settings. The program also aims to enhance participants' global networks and collaborative skills."
      },
      {
        "question": "Are there opportunities for further research or employment after completing the program?",
        "answer": "Yes, participants will have access to a network of industry and academic partners, potentially opening up opportunities for further research collaborations, employment, or academic pursuits."
      },
      {
        "question": "How will the program accommodate participants from different time zones?",
        "answer": "The program schedule will consider various time zones to ensure that live sessions are accessible to all participants. Recordings and asynchronous resources will also be available for those who cannot attend live sessions."
      },
      {
        "question": "Who can I contact for more information?",
        "answer": "For more information, please contact the program coordinator at Prof. P S Robi (psr@iitg.ac.in) or Dr.Jayesh Shanthi Bhavan (ae0281@coventry.ac.uk) . We are happy to answer any further questions you may have about the program."
      }
  ];
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  return (
    <div className="md:mx-[10%]   p-5 relative">
      <div className="text-xl md:text-3xl font-bold mb-4">Frequently Asked Questions (FAQ) </div>
      <div className="w-1 translate-x-[8.5px] md:translate-y-2 translate-y-4 bg-black h-full absolute linear-height-transition"></div>
      <div className="flex flex-col gap-3 ">
        {faqData.map((item, index) => (
          <div
            key={index}
            className={`cursor-pointer relative  ${
              selectedQuestion === index ? "text-[#CC523F]" : ""
            }`}
            onClick={() =>
              setSelectedQuestion(selectedQuestion === index ? null : index)
            }
          >
        
            <div className="  flex items-start  md:items-center gap-4  ">
           {selectedQuestion!==index?<img src="/assets/hexagon.png"  alt="dots"  className=" transition-all" width="20px"/>:
           <img src="/assets/hexagon-orange.png"  alt="dots"  className="  transition-all" width="20px"/>}
            <div className="md:text-xl  text-[17px] font-semibold transition-all">Q.{item.question}</div>
            </div>
            {selectedQuestion === index && (
              <div className="text-xl text-black ml-10 my-2 font-light transition-opacity2" >
                {item.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
