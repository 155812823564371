import React, {  useEffect, useRef } from "react";
import { Link } from "react-router-dom";
function Popup({ onClose }) {
  let menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });


  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop--sm h-screen flex justify-center items-center z-50">
      <div
        className="relative w-[91vw] sm:w-[50vw] border rounded-sm bg-white lg:w-[40vw]  py-3 my-auto overflow-y-scroll md:no-scrollbar hero__page z-50"
        ref={menuRef}
      >
    
    <div className=" flex items-center justify-center font-bold md:text-lg text-base text-center border-b-4 pb-1">
    One-Day Workshop on “Material Testing and Analysis”
        
    </div>
    <div className=" flex items-center justify-center my-4">
    <div className=" border p-4 py-10 whitespace-nowrap bg-red-50 flex flex-col items-center justify-center border-black">
          <div className="font-bold  py-1 tab_active px-5 mb-5 flex  ">
            Event Details
          </div>
          <div className="flex gap-3 my-1">
            <div className=" font-bold">Date: </div>
            <div>June 10th, 2024</div>
          </div>
          <div className="flex gap-3 my-1">
            <div className=" font-bold">Location:</div>{" "}
            IIT Guwahati, India
          </div>
          <div className="flex gap-3 my-1">
            {" "}
            <div className=" font-bold">Modes:</div>
            <div> Online and Offline</div>
          </div>
        </div></div>
      <div className=" flex flex-col px-3 mx-10 gap-2">
        <Link to='/workshop' onClick={()=>{localStorage.setItem('revisited',true)}} className=" flex items-center tab_active justify-center py-2 border  cursor-pointer  border-black">
            See More Details
        </Link>
        <div onClick={onClose} className=" flex border-2 items-center justify-center  py-2 cursor-pointer   border-black">
            Close
        </div>
      </div>


      </div>
    </div>
  );
}

export default Popup;
