import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  let menuRef = useRef();

  const toggleMenu = () => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  };

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const location = window.location.pathname;
  return (
    <div  className="fixed top-0 z-10 left-0 w-full border-b-[1px] border-black bg-[#F1ECEB] text-black p-4 flex justify-between items-center">
      <div className="flex items-center">
      <Link to={'/'} className={`hidden ${location === "/" && "font-bold"} border-r-[1px] py-1 text-lg px-4 border-black lg:flex ${isMenuOpen ? 'hidden' : ''}`}>Home</Link>
<Link to={'/contents'} className={`hidden ${location === "/contents" && "font-bold"} border-r-[1px] py-1 text-lg px-4 border-black lg:flex ${isMenuOpen ? 'hidden' : ''}`}>Contents</Link>
<Link to={'/learning-outcome'} className={`hidden ${location === "/learning-outcome" && "font-bold"} border-r-[1px] py-1 text-lg px-4 border-black lg:flex ${isMenuOpen ? 'hidden' : ''}`}>Learning Outcome</Link>
<Link to={'/team'} className={`hidden border-r-[1px] ${location === "/team" && "font-bold"} py-1 text-lg px-4 border-black lg:flex ${isMenuOpen ? 'hidden' : ''}`}>Team</Link>
{/* <Link to={'/guest-speakers'} className={`hidden ${location === "/guest-speakers" && "font-bold"} py-1 text-lg px-4 border-black lg:flex ${isMenuOpen ? 'hidden' : ''}`}>Guest Speakers</Link> */}
<div className={`relative -inset-[17px] left-[2px] hidden lg:block ${isMenuOpen ? 'hidden' : ''}`}>
  <Link to={'/workshop'} className={`absolute  ${location === "/workshop" && "font-bold"} py-1 text-lg px-4 border-black`}>Workshop</Link>
  <div className={`absolute blinking-text left-[102px] text-red-600 font-bold`}>new</div>
</div>


        {/* Responsive Menu Button for Small Screens */}
        <button className="lg:hidden focus:outline-none transition-all" onClick={toggleMenu}>
          <svg
            className="w-6 h-6 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            {isMenuOpen ? (
              <path strokeLinecap="round" className=' text-black' strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            ) : (
              <path strokeLinecap="round" strokeLinejoin="round" className=' text-black' strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            )}
          </svg>
        </button>
      </div>
      
      {/* Responsive Menu for Small Screens */}
      <div ref={menuRef} className={`lg:hidden absolute top-16 right-5 bg-[#F1ECEB] border-[1px] shadow-md border-black text-black p-5 ${isMenuOpen ? '' : 'hidden'}`}>
        <Link to={'/'} className={`block mb-2 ${location === "/" ? "font-bold" : ""}`} onClick={toggleMenu}>Home</Link>
        <Link to={'/contents'} className={`block mb-2 ${location === "/contents" ? "font-bold" : ""}`} onClick={toggleMenu}>Contents</Link>
        <Link to={'/learning-outcome'} className={`block mb-2 ${location === "/learning-outcome" ? "font-bold" : ""}`} onClick={toggleMenu}>Learning Outcome</Link>
        <Link to={'/team'} className={`block mb-2 ${location === "/team" ? "font-bold" : ""}`} onClick={toggleMenu}>Team</Link>
        {/* <Link to={'/guest-speakers'} className={`block mb-2 ${location === "/guest-speakers" ? "font-bold" : ""}`} onClick={toggleMenu}>Guest Speakers</Link> */}
        <Link to={'/workshop'} className={`block mb-2 ${location === "/workshop" ? "font-bold" : ""}`} onClick={toggleMenu}>Workshop</Link>

      </div>
    </div>
  );
}

export default Navbar;
