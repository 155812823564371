import React from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";

function Outcome() {
  const learningOutcome = {
    
      TheoreticalFoundations: [
          "Able to explain basic concepts of atomic structure, bonding, and material properties. ",
          "Able to classify materials into metals, ceramics, polymers, and composites,understanding their applications.",
          "Able to use tools like Granta Edupack for material selection with a focus on sustainability. ",
          "Able to interpret phase diagrams and use computational tools like Thermocalc for predicting material behaviours. ",
          "Able to follow safety protocols in laboratories and recognize ethical considerations in material testing.",
        
 ]     ,
      TestingAndAnalysis: [
          "Able to perform and analyze results from tensile, compression, hardness, and fatigue tests.",
          "Able to apply non-destructive testing methods for defect detection in materials.",
          "Able to draw meaningful conclusions from test data, relating material properties to their applications.",
          "Able to understand the impact of material selection on engineering design and performance.",
          "Material characterization and analysis",
          "Able to prepare samples for microscopic analysis, ensuring accuracy and integrity.",
          "Able to understand the principle of advanced instruments like SEM, TEM, XRD, and AFM for material characterization.",
          "Able to interpret data from various characterization techniques to analyze material properties.",
          "Able to integrate characterization data to assess material suitability for specific applications.",
       ] 
      ,
      CrossPhaseCompetencies: [
          "Able to analyze complex problems and engineer creative, practical solutions in materials science.",
          "Able to critically evaluate research findings and apply statistical methods for data interpretation.",
          "Able to communicate technical information effectively through written reports and presentations.",
          "Able to collaborate effectively on team projects, demonstrating teamwork and interpersonal skills.",
          "Able to incorporate ethical considerations and sustainability into materials science practices.",
        
     ] ,
      GoingGlobal: [
        "Able to navigate and thrive in interdisciplinary teams, bringing together diverse academic backgrounds and industry experiences to address complex materials science challenges.",
        "Able to understand and apply international standards and practices in materials testing, analysis, and characterization, recognizing the global nature of engineering challenges and solutions.",
        "Able to communicate effectively across cultural and language barriers, leveraging clear, inclusive communication strategies to collaborate with international peers and industry professionals.",
        "Able to leverage global networks for knowledge exchange, establishing connections with professionals and researchers across countries to stay abreast of the latest developments in materials science.",
        "Able to apply ethical considerations in a global context, understanding the varied ethical standards and practices in different countries and industries, and incorporating these into research and development projects.",
        "Able to utilize digital collaboration tools and technologies effectively for remote teamwork and project management, overcoming geographical distances to maintain productivity and project momentum.",
      ],
};
  return (
    <div>
      <Navbar />
      <div className=" md:my-[7%] md:mx-[5%] my-[20%] mx-4">
      <div className="   font-bold text-3xl mb-8  ">Learning Outcomes</div>
        <div className="   flex   items-center    ">

          <img
            src="/assets/element1.svg"
            alt="atom"
            className=" rotate-infinite  hidden   md:flex w-80"
            />
            <div className=" flex flex-col  gap-4">
                <div className="  text-[#CC523F] text-2xl font-semibold"> Theoretical Foundations</div>
                {learningOutcome["TheoreticalFoundations"].map((data, index) => (
            <div className=" flex gap-2 items-center">
              <img src="/assets/circle.svg" alt="circle" className=" rotate-infinite-fast w-5"/>  {data}
            </div>
          ))}</div>
            </div>
       

      <div className=" flex items-center justify-center my-10  ">
        <div> 
        <div className=" flex flex-col  gap-4">
                <div className="  text-[#CC523F] text-2xl font-semibold"> Testing And Analysis</div>
                {learningOutcome['TestingAndAnalysis'].map((data, index) => (
            <div className=" flex gap-2 items-center">
              <img src="/assets/circle.svg"  alt="circle"  className=" rotate-infinite-fast w-5"/>  {data}
            </div>
          ))}</div>
        <div className=" flex flex-col  gap-4">
                <div className="  text-[#CC523F] text-2xl mt-5 font-semibold"> Cross Phase Competencies</div>
                {learningOutcome['CrossPhaseCompetencies'].map((data, index) => (
            <div className=" flex gap-2 items-center">
              <img src="/assets/circle.svg"  alt="circle"  className=" rotate-infinite-fast w-5"/>  {data}
            </div>
          ))}</div>
        </div>
            <img
                src="/assets/element3.svg"
                alt="tile"
                className="hidden   md:flex  w-80"
            />
       

      </div>

          <div className="flex items-center">
  <img
            src="/assets/element2.svg"
            alt="earth"
            className=" md:hidde rotate-infinite hidden mr-4   md:flex   w-[300px]"
          />
<div className=" flex flex-col bg-global  gap-4">
                <div className="  text-[#CC523F] text-2xl font-semibold">Going Global</div>
                {learningOutcome["GoingGlobal"].map((data, index) => (
            <div className=" flex gap-2 items-center">
              <img src="/assets/circle.svg"  alt="circle"  className=" rotate-infinite-fast w-5"/>  {data}
            </div>
          ))}</div> 
          
          </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Outcome;
