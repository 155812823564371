import React from 'react';

const Footer = () => {
  return (
    <footer className="  bg-[#F4BFAE] text-white p-4 mt-48">
      <div className="container mx-auto text-black">
        <p>&copy; 2024 All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
