import React, { useState } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { useRef } from "react";
import { useEffect } from "react";
import autoAnimate from "@formkit/auto-animate";

function Contents() {
  const Theoretica_Foundations_KeyTopics = [
    {
      title: "Theory of Material Science: Introduction",
      data: "Core concepts, including atomic structure, bonding, and the structure-property relationship in different materials.",
    },
    {
      title: "Basics of Material Science",
      data: "Foundational knowledge required for understanding material properties and how they influence material selection and application.",
    },
    {
      title: "Classification of Materials",
      data: "Detailed examination of metals, ceramics, polymers, composites, and their applications, emphasizing criteria for selecting materials for specific engineering tasks.",
    },
    {
      title: "Phases - Thermocalc",
      data: "Introduction to phase diagrams and computational tools like Thermocalc for predicting material behaviors in different environments.",
    },
    {
      title: "Material Selection - Granta Edupack",
      data: "Training on using Granta Edupack software for material selection and sustainable design considerations.",
    },
    {
      title: "Laboratory Safety Protocols",
      data: "Comprehensive guidelines for safe laboratory practices, including handling of materials, equipment operation, and emergency procedures.",
    },
    {
      title: "Ethical Considerations in Material Testing",
      data: "Discussion on the ethical implications of material testing, including honesty in reporting results and consideration of environmental impact.",
    },
    {
      title: "Environmental and Disposal Guidelines for Test Materials",
      data: "Best practices for the disposal of materials and minimizing environmental impact during testing processes.",
    },
    {
      title: "Structure-Property Relationships",
      data: "In-depth analysis of how the structure of materials at various scales affects their properties and performance in applications.",
    },
  ];
  const Material_Testing_and_Analysis_KeyTopics = [
    {
      title: "Tensile and Compression Testing",
      data: "Practical sessions on measuring material strength, ductility, and toughness, including data analysis and interpretation.",
    },
    {
      title: "Hardness Testing",
      data: "Techniques for determining material hardness, including Brinell, Rockwell, and Vickers methods, and their significance in material selection.",
    },
    {
      title: "Fatigue Testing",
      data: "Introduction to fatigue testing methods for evaluating the durability and lifespan of materials under cyclic loading.",
    },
    {
      title: "Corrosion Resistance",
      data: "Experiments on assessing material corrosion rates in various environments and understanding protective measures.",
    },
    {
      title: "Electrical and Thermal Conductivity",
      data: "Measurement of material conductivity and its relevance to applications in electronics and thermal management.",
    },
    {
      title: "Non-Destructive Testing (NDT)",
      data: "Techniques like ultrasonic testing, radiography, and magnetic particle inspection for detecting internal defects without damaging the material.",
    },
    {
      title: "Fracture Toughness and Fatigue Crack Growth Test",
      data: "Understanding material resistance to crack propagation and its critical role in preventing material failure.",
    },
  ];

  const MaterialCharacterizationandAnalysis_KeyTopics = [
    {
      title: "Sample Preparation and Handling",
      data: "Techniques for preparing samples for microscopic analysis, including grinding, polishing, and ultrasonic cleaning.",
    },
    {
      title: "Optical Microscopy and SEM (Scanning Electron Microscopy)",
      data: "Basic to advanced imaging techniques for material analysis, including surface morphology and microstructural examination.",
    },
    {
      title:
        "EDS (Energy Dispersive X-ray Spectroscopy), EBSD (Electron Backscatter Diffraction)",
      data: "Techniques for elemental analysis and crystallographic orientation mapping in materials.",
    },
    {
      title: "XRD (X-ray Diffraction)",
      data: "Principles and application of XRD in determining crystal structure and phase identification.",
    },
    {
      title:
        "AFM (Atomic Force Microscopy), TEM (Transmission Electron Microscopy)",
      data: "Advanced imaging techniques for analyzing surface topography and internal structures at the atomic level.",
    },
    {
      title:
        "DSC (Differential Scanning Calorimetry), TGA (Thermogravimetric Analysis)",
      data: "Thermal analysis techniques for studying material phase transitions and degradation.",
    },
    {
      title: "Nanoindentation",
      data: "Technique for measuring mechanical properties like hardness and elastic modulus at the nanoscale.",
    },
    {
      title: "Profilometer",
      data: "Surface roughness and texture measurement, crucial for evaluating material wear and functionality.",
    },
  ];
  const [activeTab, setActiveTab] = useState("Phase I");
  const parent = useRef(null)
  useEffect(() => {
    parent.current && autoAnimate(parent.current)
  }, [parent])

  return (
    <div>
      <Navbar />
      <div className="  mt-[20%] md:mt-[7%] md:mx-[10%] mx-4">
        <div className="   my-5 font-bold text-3xl ">Contents</div>
        <div className=" flex my-5 justify-center  items-center">
          <div className=" select-none md:flex  grid grid-cols-10 grid-rows-2 md:gap-10  md:w-full text-center gap-2">
            <div
              onClick={() => setActiveTab("Phase I")}
              className={` md:px-12  px-10 font-bold  py-1 bg-red-50  col-span-5 row-span-1 ${
                activeTab === "Phase I" ? "tab_active" : "tab_inactive"
              } cursor-pointer`}
            >
              Phase I
            </div>
            <div
              onClick={() => setActiveTab("Phase II")}
              className={`md:px-12 font-bold   py-1 bg-red-50   col-span-5 row-span-1  ${
                activeTab === "Phase II" ? "tab_active" : "tab_inactive"
              } cursor-pointer`}
            >
              Phase II
            </div>
            <div
              onClick={() => setActiveTab("Phase III")}
              className={` md:px-12 font-bold  py-1 bg-red-50   col-span-5 row-span-1  ${
                activeTab === "Phase III" ? "tab_active" : "tab_inactive"
              } cursor-pointer`}
            >
              Phase III
            </div>
            <div
              onClick={() => setActiveTab("Phase IIII")}
              className={` md:px-12 font-bold  py-1 bg-red-50    col-span-5 row-span-1 ${
                activeTab === "Phase IIII" ? "tab_active" : "tab_inactive"
              } cursor-pointer`}
            >
              Workshop
            </div>
          </div>
        </div>
        {activeTab === "Phase I" && (
          <div className="flex flex-col ">
            <div className="font-bold text-xl mb-4">
              Theoretical Foundations
            </div>
            <div className="mb-4">
              <div className=" ">
                <span className="text-lg font-semibold  mr-2">Objective:</span>
              <span className=" font-medium">
                To provide participants with a solid understanding of the
                fundamental principles of materials science, including the
                basics of material properties, classification, and selection, as
                well as important considerations in laboratory safety and
                ethics.
              </span>
            </div></div>
            <div></div>
            <div className="  relative">
            <div className="mb-4 text-lg font-bold">Key Topics:</div>
              
            <div className="w-1 translate-x-[8.5px] md:translate-y-2 translate-y-4 bg-black h-full absolute linear-height-transition"></div>
<div className="flex flex-col gap-3 ">
  {Theoretica_Foundations_KeyTopics.map((item, index) => (
    <div key={index} className={`relative`}>

      <div className="flex items-center gap-4">
     <img src="/assets/hexagon.png" alt="dots" width="20px"/>
        <div className="md:text-xl text-base font-bold">{item.title}</div>
      </div>

      <div ref={parent} className={`text-xl text-black ml-10 my-2 font-light`}>
        {item.data}
      </div>

    </div>
  ))}
</div>

    </div>
          </div>
        )}
 {activeTab === "Phase II" && (
  <div className="flex flex-col">
    <div className="font-bold text-xl mb-4">
      Material Testing and Analysis
    </div>
    <div className="mb-4">
      <p className="">
      <span className=" text-lg font-bold mr-2">Objective:</span>
        To equip participants with hands-on experience in conducting
        standard materials testing and analysis techniques, interpreting
        results, and understanding their implications in real-world
        applications.
      </p>
    </div>
    <div className="relative">
      <div className="mb-4 text-lg font-bold">Key Topics:</div>
      <div className="w-1 translate-x-[8.5px] md:translate-y-2 translate-y-4 bg-black h-full absolute linear-height-transition"></div>
      <div className="flex flex-col gap-3">
        {Material_Testing_and_Analysis_KeyTopics.map((item, index) => (
          <div
            key={index}
            className={` relative`}
          >
            <div className="flex items-center gap-4">
            <img src="/assets/hexagon.png" alt="dots"  width="20px"/>
              <div className="md:text-xl text-base font-bold">{item.title}</div>
            </div>
            <div className="text-xl text-black ml-10 my-2 font-light">
              {item.data}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)}

{activeTab === "Phase III" && (
  <div className="flex flex-col">
    <div className="font-bold text-xl mb-4">
      Material Characterization and Analysis
    </div>
    <div className="mb-4">
      <p className="">
      <span className="text-lg font-bold mr-2">Objective:</span>
        To delve into advanced material characterization techniques,
        providing participants with the skills to analyze and interpret
        material properties at the micro and nanoscale, essential for
        research and development in materials science.
      </p>
    </div>
    <div className="relative">
      <div className="mb-4 text-lg font-bold">Key Topics:</div>
      <div className="w-1 translate-x-[8.5px] md:translate-y-2 translate-y-4 bg-black h-full absolute linear-height-transition"></div>
      <div className="flex flex-col gap-3">
        {MaterialCharacterizationandAnalysis_KeyTopics.map((item, index) => (
          <div
            key={index}
            className={` relative`}
          >
            <div className="flex items-center gap-4">
            <img src="/assets/hexagon.png" alt="dots"  width="20px"/>
              <div className="md:text-xl text-base font-bold">{item.title}</div>
            </div>
            <div className="text-xl text-black ml-10 my-2 font-light">
              {item.data}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)}

        {activeTab === "Phase IIII" && (
          <div className="flex flex-col">
            <div className="font-bold text-xl mb-4">
              Workshops and Real Data Analysis Projects
            </div>
            <div className="mb-4">
              <p className=" ">
              <span className=" text-lg font-bold mr-2">Objective:</span>
                Each phase includes workshops designed to reinforce learning
                through practical application, followed by projects involving
                real data analysis to simulate professional materials testing
                and analysis scenarios. Participants will analyze data, draft
                reports, and receive feedback, preparing them for real-world
                challenges in materials science.
              </p>
            </div>
          </div>
        )}
      </div>
      <Footer/>
    </div>
  );
}

export default Contents;
