import React from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";

function Workshop() {
  const data = {
    
    events: [
      {
        time_BST: "9.00 - 11.45",
        time_IST: "13.30 to 16.15",
        event: "Hands on Material Test Analysis (Online)",
        resource_person: "",
      },
      {
        time_BST: "12.00 - 1.00",
        time_IST: "16.30 - 17.30",
        event: "Guest Speaker",
        resource_person: "Dr. Chidambaram Subramanian (Senior Scientist, CSIR)",
      },
      {date: "10-06-2014",
      day: "Monday",
        time_BST: "1.00 - 2.00",
        time_IST: "17.30 - 18.30",
        event: "Guest Speaker",
        resource_person: "Dr. Jagdish Jethwa (Principal Engineer, LEVC)",
      },
      {
        time_BST: "3.00 - 4.00",
        time_IST: "18.30 - 19.30",
        event: "Guest Speaker",
        resource_person: "Dr. Inderdeep Singh (Professor, IIT Roorkee)",
      },
      {
        time_BST: "4.00 - 5.00",
        time_IST: "19.30 - 20.30",
        event: "Guest Speaker",
        resource_person: "Mr. Amitava Bhattacharjee (DGM, NRL)",
      },
    ],
  };
  const members = [
    "Dr. Jayesh Shanthi Bhavan (Lead, UK), Coventry University",
    "Dr. P S Robi (Lead, India), IT Guwahati",
    "Mr. Gojen Doley (Industry Lead), NRL",
    "Dr. Perumal Alagarsamy, IIT Guwahati",
    "Dr. R. Prasanna Venkatesh, IIT Guwahati",
    "Dr. Mahshid Yazdi Far, Coventry University",
    "Mr. Prangan Bhattacharyya, NRL",
    "Mr. Javed Mahammed, NRL",
    "Mr. Deepjyoti Deka, NRL",
    "Dr. Gokulnath Kadavath, CET",
    "Mr. Sreekumar CR, GECT",
  ];
  return (
    <div>
      <Navbar />
      <div className=" md:my-[7%] md:mx-[5%] my-[20%] mx-4">
        <div className="   font-bold text-3xl mb-8  ">
          One-Day Workshop on “Material Testing and Analysis”
        </div>

        <div className=" md:flex md:flex-row flex gap-7  items-center  md:gap-2 flex-col">
          <div className=" border p-4 py-10 whitespace-nowrap bg-red-50 flex flex-col items-center justify-center border-black">
            <div className="font-bold  py-1 tab_active px-5 mb-5 flex  ">
              Event Details
            </div>
            <div className="flex gap-3 my-1">
              <div className=" font-bold">Date: </div>
              <div>June 10th, 2024</div>
            </div>
            <div className="flex gap-3 my-1">
              <div className=" font-bold">Location:</div> IIT Guwahati, India
            </div>
            <div className="flex gap-3 my-1">
              
              <div className=" font-bold">Modes:</div>
              <div> Online and Offline</div>
            </div>
          </div>
          <div className="  text-[18px]  md:px-10 md:text-2xl md:text-left px-9 text-center font-extralight">
            This workshop will be held at IIT Guwahati, accommodating both
            Indian and UK participants by adjusting the schedule accordingly.
            Participants are welcome to attend the workshop in person or join
            online if unable to travel. Don't miss this opportunity to enhance
            your skills and knowledge in material testing and analysis!
          </div>
        </div>
        <div className=" flex w-full overflow-scroll no-scrollbar">
        <div className="  w-[720px]   md:w-full flex flex-col my-10 text-center border-black border gap-">
          <div className=" md:w-full flex  w-[720px]">
          <div className=" w-1/6 border-r   border-b border-black">
            
            Date
          </div>
          <div className=" w-1/6 border-r border-b border-black">
            
            Time (BST)
          </div>
          <div className=" w-1/6  border-r  border-b border-black">
            
            Time (IST)
          </div>
          <div className=" w-1/3 border-r border-b border-black">
            
            Event
          </div>
          <div className=" w-1/3 border-b  border-black">
            
            Resource Person
          </div></div>
         
        
        
        
          
  
          {data.events.map((event,index) => (
            <div  className=" flex">
             <div className="    w-1/6  border-r border-black ">
            {event.date&&event.date}
            <br />{event.day&&event.day}
          </div>
              <div className={`w-1/6 py-4 border-r border-b  border-black }`}>{event["time_BST"]}</div>
              <div className="w-1/6 py-4 border-r border-b border-black ">{event["time_IST"]}</div>
              <div className="w-1/3 py-4 border-r border-b border-black ">{event["event"]}</div>
              <div className="w-1/3 py-4 border-b border-black ">
                {event["resource_person"]}
              </div>
            </div>
          ))}     
          

        </div>
        </div>
        <div className=" font-bold text-3xl mb-8">Workshop Committee</div>
        <div className=" flex flex-col">{members.map((member,index)=><div className="  text-lg font-thin"><span className=" font-bold pr-2">{index+1}.</span>  {member}</div>)}</div>
      </div>

      <Footer />
    </div>
  );
}

export default Workshop;
